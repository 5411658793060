import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

import { LocationDetails } from "src/components/location-details";

import { fDateString } from "src/utils/formatTime";

import { Location } from "src/models/location";
import { shipmentServices } from "../../../constants/services";
import { valueGetter } from "./DetailsDrawer";
import { ServiceField, ServicesDetails } from "./ServicesDetails";

export function LocationsDetails({ shipment }) {
  return (
    <>
      {/* PICKUP & STOP */}
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          width: "100%",
          gap: "0.75rem",
          mt: "0.75rem"
        }}
      >
        <LocationDetails
          locationLabel={"Origin"}
          location={new Location().fromPickup(shipment.pickups[0])}
          type={"pickup"}
          customFields={
            <Stack>
              <TargetDates early={shipment.pickups[0].targetShipEarly} late={shipment.pickups[0].targetShipLate} />
              <Divider sx={{ mt: '0.8rem' }} />
              <ContactInfo
                name={shipment.pickups[0].originContact}
                phone={shipment.pickups[0].originPhone}
                email={shipment.pickups[0].originEmail}
              />
              <Services
                services={shipmentServices.find(servicePerMode => servicePerMode.mode == shipment.mode)?.pickups}
                shipment={shipment}
              />
            </Stack>
          }
        />
        <LocationDetails
          locationLabel={"Destination"}
          location={new Location().fromStop(shipment.stops[0])}
          type={"stop"}
          customFields={
            <Stack>
              <Stack>
                <TargetDates early={shipment.stops[0].targetDeliveryEarly} late={shipment.stops[0].targetDeliveryLate} />
                <Divider sx={{ mt: '0.8rem' }} />
                <ContactInfo
                  name={shipment.stops[0].destContact}
                  phone={shipment.stops[0].destPhone}
                  email={shipment.stops[0].destEmail}
                />
                <Services
                  services={shipmentServices.find(servicePerMode => servicePerMode.mode == shipment.mode)?.stops}
                  shipment={shipment}
                />
              </Stack>
            </Stack>
          }
        />
      </Box>
      <ServicesDetails shipment={shipment} />
    </>
  );
}

function ContactInfo({ name, phone, email }) {
  return (
    <Box sx={{ mt: '0.5rem' }}>
      <Typography fontWeight={600} mt={1}>
        POC: {valueGetter(name)}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          display: {
            md: "flex",
            xs: "block"
          },
          width: '100%',
          py: "0.25rem",
          gap: "0.5rem"
        }}>
          <Typography>Phone:</Typography>
          <Typography fontWeight={600} textAlign={{
            md: "right",
            xs: "left"
          }}>
            {valueGetter(phone)}
          </Typography>
        </Box>
        <Box sx={{
          display: {
            md: "flex",
            xs: "block"
          },
          width: '100%',
          py: "0.25rem",
          gap: "0.5rem"
        }}>
          <Typography>E-Mail:</Typography>
          <Typography fontWeight={600} textAlign={{
            md: "right",
            xs: "left"
          }}>
            {valueGetter(email)}
          </Typography>
        </Box>
      </Box></Box>);
}

function Services({ services, shipment }) {
  return (
    <Box py={1}>
      <Typography fontWeight={600}>Services</Typography>
      <Grid container gap={'1rem'}>
        {services.map((service, index) => {
          const [category, key] = service.name.split(".");
          return (
            <Grid item>
              <ServiceField service={service} key={service.name + index} checked={shipment?.[category] ? shipment?.[category][key] : false} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

function TargetDates({ early, late }) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}>
      <Box sx={{
        display: {
          md: "flex",
          xs: "block"
        },
        width: '100%',
        gap: "0.5rem"
      }}>
        <Typography>Target Early: </Typography>
        <Typography fontWeight={600} textAlign={{
          md: "right",
          xs: "left"
        }}>
          {valueGetter(fDateString(early))}
        </Typography>
      </Box>
      <Box sx={{
        display: {
          md: "flex",
          xs: "block"
        },
        width: '100%',
        gap: "0.5rem"
      }}>
        <Typography>Target Late: </Typography>
        <Typography fontWeight={600} textAlign={{
          md: "right",
          xs: "left"
        }}>
          {valueGetter(fDateString(late))}
        </Typography>
      </Box>
    </Box>);
}
