import { Box } from "@mui/material";
import { useActionables } from "./actionables";
import { useShipmentQueries } from "../../hooks/useShipmentQueries";
import DialogUncombineShipment from "./DialogUncombineShipment";
import { useParams } from "react-router-dom";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import { useState } from "react";

export function ShipmentActions() {
  const { shipment, uncombine } = useShipmentDetails();

  const actionables = useActionables({
    onUncombineTapped: () => {
      setUncombineConfirmDialogVisilibity(true)
    }
  });

  const [isUncombineConfirmDialogOpened, setUncombineConfirmDialogVisilibity] = useState(false);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {actionables
          .filter((action) => action.isShown)
          .map(({ Actionable, key }) => (
            <Actionable key={key} />
          ))}
      </Box>
      <DialogUncombineShipment
        onConfirm={() => {
          setUncombineConfirmDialogVisilibity(false);
          uncombine({ shipmentId: shipment._id});
        }}
        onClose={() => { setUncombineConfirmDialogVisilibity(false) }}
        open={isUncombineConfirmDialogOpened}
      />
    </>

  );
}
