import { Autocomplete, Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { debounce, isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSnackbar } from "src/hooks/useSnackbar";

import { OrderDetailListItem } from "./SearchItemOrderDetails";
import { useAuth } from "src/hooks/useAuth";
import { OrderService } from "src/api/orders.service";
import { ErrorMessage } from "src/components/ErrorMessage";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";
import { Order } from "src/models/order";

export function PONumberField() {
  const { session } = useAuth();
  const { setSnackbarMessage } = useSnackbar();
  const { control, setError } = useFormContext();
  const {
    mapFields: fieldMapper,
    items: { fields: items },
  } = useShipmentFormContext();

  const [loading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderSelected, setSelectedOrder] = useState(null);
  const [pickupSelected, setSelectedPickup] = useState(null);

  const abortControllerRef = useRef(null);

  const service = new OrderService(session.token, session.tenant._id);

  const debounceEffect = debounce((poNumber) => {
    if (poNumber?.length && poNumber != "") {
      const fetchData = async () => {
        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        try {
          const poNumbers = poNumber.split(',').filter(x=>x);

          const result = await service.postValidatePoNumbers(
            poNumbers,
            abortControllerRef.current?.signal
          );
          // If fetch is successful, update options
          if (result?.validated?.length && result?.validated[0]?.orders?.length) {
            const orders = result.validated.map( result => result.orders ).flat()
            setOrders(orders);
          }
          else {
            setSnackbarMessage({
              message: <ErrorMessage response={result} defaultMessage={'Failed to validate PO Number'} />,
              severity: "error",
            });
          }
          setIsLoading(false)
        } catch (err) {
          // Fetch error occurred
          if (err?.name == "AbortError") {
            console.log('canceled');
            return;
          }

          setIsLoading(false);
          setSnackbarMessage({
            message: "Failed to validate PO Number",
            severity: "error",
          });
          setOrders([]);
        }
      }
      fetchData();
    }
  }, 1500);

  // Fetch PO Numbers on component mount if user is a SupplierManager
  useEffect(() => {
    const fetchPoNumbers = async () => {
      if (session.permissions.IsSupplierManager) {
        try {
          const result = await service.getPoNumbers();
          setOrders(result);
        } catch (err) {
          setSnackbarMessage({
            message: "Failed to fetch PO Numbers",
            severity: "error",
          });
        }
      }
    };
    fetchPoNumbers();
  }, [session]);

  const options = useMemo(() => {
    if (loading) return [];
    return orders?.map((order) => {
      return { label: `${poNumberFromOrder(order)}`, ...order };
    });
  }, [orders, loading]);

  function handleInputChange(event, value) {
    if (!event) return;
    // Check if input value matches any existing option
    const match = options.filter(option => option.label.includes(value));

    if (!match.length) {
      // If no match, call debounceEffect to fetch from API
      debounceEffect(value);
      setIsLoading(!!value);
    }

    return () => debounceEffect.cancel();
  }

  function poNumberFromOrder(order) {
    return order
      ? `${order?.PO_NUM}${order?.RELEASE_NUM != undefined ? "-" + order.RELEASE_NUM : ""
      }`
      : "";
  }
  // MUST RETURN AN ARRAY `{ PO_NUM }[]`
  function formatFieldValue(value) {
    if (Array.isArray(value)) {
      return value;
    }
    if (typeof value === "string") return [value];
    return [];
  }

  
  const poNumb = () => {
    let poNumber = ''
    if (session.tenant.code === 'COX') {
      poNumber = 'COX PO Number:'
    } else {
      poNumber = 'PO Number:'
    }
    
    return  poNumber
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={'100%'}
    >
      <Controller
        name={"poNumber"}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const { onChange, value } = field;
          return (
            <Autocomplete
              {...field}
              multiple
              limitTags={1}
              freeSolo
              loading={loading}
              fullWidth
              open={open}
              autoComplete
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              options={options}
              filterOptions={(options, { inputValue }) => {
                if(inputValue?.includes(',')) {
                  return options.filter(option => option.label);
                }
                return options.filter(option => option.label.includes(inputValue))
              }}
              includeInputInList
              value={formatFieldValue(value)}
              onChange={(event, orders) => {
                const updatedOrders = orders.map((order) => order.label ? order.label : order);
                onChange(updatedOrders);
                if (isEmpty(updatedOrders)) {
                  setSelectedOrder(null);
                  setSelectedPickup(null);
                }
              }}
              onInputChange={handleInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={formatFieldValue(value).length == 0 ? poNumb() : ""}
                  fullWidth
                  label={poNumb()}
                  {...error}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <OrderDetailListItem
                  key={option._id}
                  customProps={props}
                  order={option}
                  onOrderSelect={(value) => {
                    setSelectedOrder(value);
                  }}
                />
              )}
            />
          );
        }}
      />
      {
        (orderSelected !== null && orderSelected.pickup.length > 1) &&
        <FormControl fullWidth margin="normal" >
          <InputLabel id="select-pickup">Select the pickup</InputLabel>
          <Select
            labelId="select-pickup"
            value={pickupSelected !== null ? pickupSelected : {}}
            label="Select the pickup"
            onChange={(event) => {
              let newOrder = structuredClone(orderSelected);
              newOrder.pickup = event.target.value;
              const shipmentAux = Order.responseToShipment(newOrder, true);
              setSelectedPickup(event.target.value);
              fieldMapper(shipmentAux);
            }}
          >
            {
              orderSelected.pickup.map((pickup, index) => {
                return <MenuItem value={pickup} key={index}>{pickup.addr1}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      }
    </Box>
  );
}