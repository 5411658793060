import React, { useState } from "react";
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@mui/material";
import { StandardDialog } from "src/components/dialog/Dialog";
import { LoadingButton } from "@mui/lab";

export default function DialogInitialTutorial(props) {
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        localStorage.setItem("initialTutorialDialog", event.target.checked);
        setChecked(event.target.checked);
    };

    return <StandardDialog
        onClose={props.onClose}
        isOpen={props.open}>
        <Box sx={{ width: '100%' }}>
            <DialogContent>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    <img src="/assets/welcome-aboard-pana.svg" alt="Descrição do SVG" width={"30%"} />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <DialogTitle sx={{ padding: 0 }}>Welcome, {props.userName}</DialogTitle>
                        <DialogContentText>
                            We're happy to have you here, please, read our guide on how to create a shipment.
                        </DialogContentText>
                        <Box height={"24px"} />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    name="neverSeeThisAgain"
                                    color="primary"
                                />
                            }
                            label="Never see this again!"
                        />
                    </Box>
                </Box>
                <DialogActions>
                    <Button variant="outlined" onClick={props.onContactUs}>Contact us</Button>
                    <LoadingButton loading={props.onReadMoreLoading}
                        variant="contained" onClick={props.onReadMore}>Read more</LoadingButton>
                </DialogActions>
            </DialogContent>
        </Box>
    </StandardDialog>
}