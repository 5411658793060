import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, ControlPointDuplicate, Edit } from "@mui/icons-material";
import { Box, Button, Link, Tooltip } from "@mui/material";
import { Compare } from "src/utils/compare";
import { getEquipmentFromShipment } from "src/utils/shipment";
import { shipmentServices } from "./services";

export const backofficeShipmentColumns = (session) => {

    let poNumberLabel = "PO Number";
    if (session?.tenant?.code === "COX") {
      poNumberLabel = "COX PO Number";
    }
  

    return [
        {
            field: "poNumber",
            headerName: poNumberLabel,
            minWidth: 100,
        },
        {
            field: "primaryReference",
            headerName: "Primary Reference",
            minWidth: 120,
            valueGetter: (params) => {
                /// Merge original primary references for combined shipments for users be able to search for.
                const shipment = params.row;
                const result = `${shipment.primaryReference}, ${shipment.combinedFrom?.map((combined) => combined.primaryReference).join(', ') ?? ''}`;
                return result;
            },
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.primaryReference}`}>
                        {params.row.primaryReference}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.primaryReference}
                        </div>
                    )
                }
            },            
        },
        {
            field: "services",
            headerName: "Services",
            minWidth: 120,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${getServiceShipment(params.row)}`}>
                        {getServiceShipment(params.row)}
                    </div>
                    );
                } else {
                    return (
                        <div>
                            {getServiceShipment(params.row)}
                        </div>
                    )
                }
            },   
            valueGetter: (params) => getServiceShipment(params.row),
        },
        {
            field: "mode",
            headerName: "Mode",
            minWidth: 80,
            align: 'center',
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.mode?.toUpperCase()}`}>
                        {params.row.mode?.toUpperCase()}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.mode?.toUpperCase()}
                        </div>
                    )
                }
            },               
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 120,
            align: 'center',
            renderCell: (params) =>  {
                const capitalize = (str) => {
                    if (typeof str === 'string') {
                        return str.charAt(0).toUpperCase() + str.slice(1);
                    }                    
                };
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.metadata?.invalidPoNumber}`}>
                        {capitalize(params.row.status)}
                    </div>
                    );
                } else {
                    return (
                        <div className={`cellWithStatus ${params.row.status}`}>
                            {params.row.status}
                        </div>
                    );
                }
            },
        },
        {
            field: "customer",
            headerName: "Customer",
            minWidth: 150,
            align: 'center',
            renderCell: (params) => { 
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.tenant?.name}`}>
                        {params.row.tenant?.name}
                    </div>
                    );
                } else {
                    return(
                        <div>{params.row.tenant?.name}</div>
                    )
                }
        },
            valueGetter: (params) => params.row.tenant?.name,
        },
        {
            field: "created.at",
            headerName: "Created At",
            minWidth: 150,
            align: 'center',
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${fDateString(params.row.created?.at)}`}>
                        {fDateString(params.row.created?.at)}
                    </div>
                    );
                } else {
                    return(
                    <div>{fDateString(params.row.created?.at)}</div>
                    )
                }
            },
            valueGetter: (params) => params.row.created?.at,
            sortComparator: Compare.dayInMoth,
        },
        {
            field: "created.by",
            headerName: "Created By",
            minWidth: 130,
            align: 'center',
            renderCell: (params) => {
                const by = params.row.created?.by;
                if (params.row.metadata?.invalidPoNumber) {
                    if (!by) return <>--</>
                    return (
                    <div className={`invalidPoNumber ${by?.firstname} ${by?.lastname}`}>
                        <>{`${by?.firstname} ${by?.lastname}`}</>
                    </div>
                    );
                } else {
                    if (!by) return <>--</>
                    return <>{`${by?.firstname} ${by?.lastname}`}</>
                }
            },
            valueGetter: (params) => {
                const by = params.row.created?.by;
                return `${by?.firstname} ${by?.lastname}`;
            },
        },
        {
            field: "integrated",
            headerName: "Confirmed",
            minWidth: 80,
            align: 'center',
            renderCell: (params) => params.row.integrated
                ? <CheckBoxOutlined sx={{ opacity: 0.8 }} /> : <CheckBoxOutlineBlankOutlined sx={{ opacity: 0.8 }} />,
        },
        {
            field: "quantity",
            headerName: "Pallets",
            minWidth: 100,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.quantity}`}>
                        {params.row.quantity}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.quantity}
                        </div>
                    )
                }
            }
        },
        {
            field: "weight",
            headerName: "Weight",
            minWidth: 100,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.weight}`}>
                        {params.row.weight}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.weight}
                        </div>
                    )
                }
            }    
        },
        {
            field: "equipment",
            headerName: "Equipment",
            minWidth: 100,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${getEquipmentFromShipment(params.row) ?? '--'}`}>
                        {getEquipmentFromShipment(params.row) ?? '--'}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {getEquipmentFromShipment(params.row) ?? '--'}
                        </div>
                    )
                }
            },
            valueGetter: (params) => getEquipmentFromShipment(params.row) ?? '--',
        },
        {
            field: "carrier",
            headerName: "Carrier",
            minWidth: 100,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${getCarrierShipment(params.row)}`}>
                        {getCarrierShipment(params.row)}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {getCarrierShipment(params.row)}
                        </div>
                    )
                }
            },
            valueGetter: (params) => getCarrierShipment(params.row),
        },
        {
            field: "pickups.originName",
            headerName: "Shipper",
            minWidth: 250,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.pickups[0]?.originName}`}>
                        {params.row.pickups[0]?.originName}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.pickups[0]?.originName}
                        </div>
                    )
                }
            },            
            valueGetter: (params) => params.row.pickups[0]?.originName,
        },
        {
            field: "pickups.originCity",
            headerName: "Origin City",
            minWidth: 200,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.pickups[0]?.originCity}`}>
                        {params.row.pickups[0]?.originCity}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.pickups[0]?.originCity}
                        </div>
                    )
                }
            },            
            valueGetter: (params) => params.row.pickups[0]?.originCity,
        },
        {
            field: "pickups.originAddr1",
            headerName: "Origin Adress",
            minWidth: 200,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.pickups[0]?.originAddr1}`}>
                        {params.row.pickups[0]?.originAddr1}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.pickups[0]?.originAddr1}
                        </div>
                    )
                }
            },            
            valueGetter: (params) => params.row.pickups[0]?.originAddr1,
        },
        {
            field: "pickups.originState pickups.originZip pickups.originCtry",
            headerName: "Origin State/Zip/Country",
            minWidth: 240,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.pickups[0]?.originState} / ${params.row.pickups[0]?.originZip} / ${params.row.pickups[0]?.originCtry}`}>
                        {params.row.pickups[0]?.originState} / {params.row.pickups[0]?.originZip} / {params.row.pickups[0]?.originCtry}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.pickups[0]?.originState} / {params.row.pickups[0]?.originZip} / {params.row.pickups[0]?.originCtry}
                        </div>
                    )
                }
            },                 
            valueGetter: (params) => params.row.pickups[0]?.originState,
        },
        {
            field: "pickups.targetShipLate",
            headerName: "Ship Date",
            minWidth: 200,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${fDateString(params.row.pickups[0]?.targetShipLate)}`}>
                        {fDateString(params.row.pickups[0]?.targetShipLate)}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {fDateString(params.row.pickups[0]?.targetShipLate)}
                        </div>
                    )
                }
            },                   
            // PASSES TO valueFormatter
            valueGetter: (params) => {
                return fDateString(params.row.pickups[0]?.targetShipLate);
            },
        },
        {
            field: "stops.destName",
            headerName: "Receiver",
            minWidth: 250,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.stops[0]?.destName}`}>
                        {params.row.stops[0]?.destName}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.stops[0]?.destName}
                        </div>
                    )
                }
            },    
            valueGetter: (params) => params.row.stops[0]?.destName,
        },
        {
            field: "stops.destCity",
            headerName: "Destination City",
            minWidth: 200,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.stops[0]?.destCity}`}>
                        {params.row.stops[0]?.destCity}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.stops[0]?.destCity}
                        </div>
                    )
                }
            },            
            valueGetter: (params) => params.row.stops[0]?.destCity,
        },
        {
            field: "stops.destAddr1",
            headerName: "Dest. Address",
            minWidth: 200,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.stops[0]?.destAddr1}`}>
                        {params.row.stops[0]?.destAddr1}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.stops[0]?.destAddr1}
                        </div>
                    )
                }
            },                
            valueGetter: (params) => params.row.stops[0]?.destAddr1,
        },
        {
            field: "stops.destState stops.destZip stops.destCtry",
            headerName: "Dest. State/Zip/Country",
            minWidth: 240,
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${params.row.stops[0]?.destState} / ${params.row.stops[0]?.destZip} / ${params.row.stops[0]?.destCtry}`}>
                        {params.row.stops[0]?.destState} / {params.row.stops[0]?.destZip} / {params.row.stops[0]?.destCtry}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {params.row.stops[0]?.destState} / {params.row.stops[0]?.destZip} / {params.row.stops[0]?.destCtry}
                        </div>
                    )
                }
            },               
            valueGetter: (params) => `${params.row.stops[0]?.destState} / ${params.row.stops[0]?.destZip} / ${params.row.stops[0]?.destCtry}`,
        },
        {
            field: "stops.targetDeliveryLate",
            headerName: "Delivery Date",
            minWidth: 200,
            // PASSES TO valueFormatter
            renderCell: (params) => {
                if (params.row.metadata?.invalidPoNumber) {
                    return (
                    <div className={`invalidPoNumber ${fDateString(params.row.stops[0]?.targetDeliveryLate)}`}>
                        {fDateString(params.row.stops[0]?.targetDeliveryLate)}
                    </div>
                    );
                } else {
                    return(
                        <div>
                            {fDateString(params.row.stops[0]?.targetDeliveryLate)}
                        </div>
                    )
                }
            },               
            valueGetter: (params) => {
                return fDateString(params.row.pickups[0]?.targetShipLate);
            },
        },
        {
            field: "trackingUrl",
            headerName: "Tracking URL",
            minWidth: 200,
            renderCell: (params) => params.row.trackingUrl ? <Button href={params.row.trackingUrl}>Tracking</Button> : <Unavailable />,
        },
        {
            field: "bolFile.url",
            headerName: "BOL",
            minWidth: 200,
            renderCell: (params) => params.row.bolFile ? <Link href={params.row.bolFile?.url}>Download BOL</Link> : '',
        },
        {
            field: "podFile.url",
            headerName: "POD",
            minWidth: 200,
            renderCell: (params) => params.row.podFile ? <Link href={params.row.podFile?.url}>Download POD</Link> : '',
        },
        {
            field: "integrations.metadata.bolNumber",
            headerName: "BOL Number",
            minWidth: 200,
            valueGetter: (params) => getBOLNumber(params.row),
        },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box>
                        <Tooltip title="Duplicate">
                            <Button onClick={
                                (e) => {
                                    e.stopPropagation();
                                }}
                                href={`/shipments/${params.row._id}/edit?duplicate`}
                            >
                                <ControlPointDuplicate />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <Button
                                onClick={(e) => e.stopPropagation()}
                                href={`/shipments/${params.row._id}/edit`}
                            ><Edit /></Button>
                        </Tooltip>
                    </Box>
                )
            },
        }
    ];
}

const Unavailable = () => (
    <Button disabled sx={{ fontWeight: 200 }}>Unavailable</Button>
)

const getBOLNumber = (shipment) => {
    const BOLNumber = shipment.integrations?.find(integration => (
        integration.target == 'banyan' &&
        integration.status == 'success' &&
        !!integration?.metadata?.bolNumber
    )
    )?.metadata.bolNumber;
    return BOLNumber || ''
}

const getCarrierShipment = (shipment) => {
    if (shipment.mode === 'LTL') {
        const carrier = shipment.integrations?.find(integration => (integration.status == 'success'));
        return carrier?.metadata?.carrier ?? '--';
    } else {
        return 'Trinity Logistic';
    }
}

const getServiceShipment = (shipment) => {
    const allServices = [
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "LTL").services,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "LTL").pickups,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "LTL").stops,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "TL").services,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "TL").pickups,
        ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "TL").stops,
    ];

    if (shipment.services) {
        const shipmentServiceLabels = [...new Set(
            allServices
                .filter(service => shipment.services.hasOwnProperty(service.key) && shipment.services[service.key])
                .map(service => service.label)
        )];

        if (shipmentServiceLabels.length > 0) {
            return shipmentServiceLabels.join(", ");
        }
    }

    return '--';
}


const fDateString = (dateString) => {
    if (!dateString) return ' -- '
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${month}/${day}/${year} ${hours}:${minutes}`;
};